import React, { FC } from 'react'
import { Button, Link } from '..'
import classNames from 'classnames'
import { useEffect } from 'react'
import { IButtonLink } from './type'

/**
 * Component
 */
const ButtonLink: FC<IButtonLink> = ({
  align,
  id,
  children,
  className,
  href,
  nofollow,
  target,
  theme,
  size,
  onClick,
}) => {
  useEffect(() => {
    const hasWisepopsLink = document.querySelectorAll("a[href='#wisepops']")
    const hasGtmScript = document.getElementById('gtm')

    // Load gtm script for wisepops content
    // TO DO : improve code by loading script if buttonLink is in viewport
    if (!hasGtmScript && hasWisepopsLink.length) {
      const gtm = document.createElement('script')
      gtm.innerHTML = `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.GATSBY_GOOGLE_TAGMANAGER_ID}');
        `
      gtm.id = 'gtm'
      document.body.appendChild(gtm)
    }
  })

  return (
    <div id={id}>
      <Link
        href={href}
        target={target}
        className={classNames('button', 'button--type-link', className)}
        onClick={onClick}
        nofollow={nofollow}
      >
        <Button align={align} size={size} theme={theme}>
          {children}
        </Button>
      </Link>
    </div>
  )
}
ButtonLink.displayName = 'ButtonLink' // https://github.com/facebook/react/issues/4915#issuecomment-335803765

export default ButtonLink
