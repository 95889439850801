import React, { useEffect, FC, lazy, Suspense } from 'react'
import { Navigation } from '..'
import { getBrand, getTranslations, getHeaderItems } from '../../utils/context'

import {
  footerSocials,
  footerColumns,
  footerCopyrights,
  defaultFooterCta,
} from './brandProps'
import { Script } from 'gatsby'

const Footer = lazy(() => import('../Footer'))

enum EHeaderType {
  BLOG = 'BLOG',
  SERENITY_US = 'SERENITY_US',
  SERENITE = 'SERENITE',
  LIBERTE = 'LIBERTE',
  COMMUNICATION = 'COMMUNICATION',
  COMPTABILITE = 'COMPTABILITE',
  PAIEMENT = 'PAIEMENT',
  PRO = 'PRO',
}

interface ILayoutWithoutHead {
  children: React.ReactNode
  preFooter?: React.ReactNode
  headerBackgroundColor?: string
  isBlog?: boolean
  footerBackground?: string
  partnershipLink?: string
  header?: EHeaderType
  loadGtm?: boolean
  footerColumnsProps?: Array<{ [key: string]: string | boolean }>
}

/**
 * Component
 */
const LayoutWihoutHead: FC<ILayoutWithoutHead> = ({
  children,
  preFooter,
  headerBackgroundColor = 'blue',
  isBlog = false,
  footerBackground = 'clearBlue',
  partnershipLink,
  header,
  loadGtm,
  footerColumnsProps,
}) => {
  const translations = getTranslations()

  const brand = getBrand()

  // GTM script specific injection to overcome onscroll rules.
  useEffect(() => {
    const hasGtmScript = document.getElementById('gtm')

    if (!hasGtmScript && loadGtm) {
      const gtm = document.createElement('script')
      gtm.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.GATSBY_GOOGLE_TAGMANAGER_ID}');
      `
      gtm.id = 'gtm'
      document.body.appendChild(gtm)
    }
  }, [loadGtm])

  if (brand === undefined) {
    return <></>
  }

  return (
    <>
      <Script data-cfasync="false">
        {`
        (function(w,i,s,e){window[w]=window[w]||function(){(window[w].q=window[w].q||[]).push(arguments)};window[w].l=Date.now();s=document.createElement('script');e=document.getElementsByTagName('script')[0];s.defer=1;s.src=i;e.parentNode.insertBefore(s, e)})('wisepops', 'https://wisepops.net/loader.js?v=2&h=${process.env.GATSBY_WISEPOP_ID}');
        `}
      </Script>

      <Script id="gtm">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.GATSBY_GOOGLE_TAGMANAGER_ID}');
      `}
      </Script>

      <Script id="axeptio">
        {`
          window.axeptioSettings = {
            clientId:"${process.env.GATSBY_AXEPTIO_CLIENT_ID}",
            cookiesVersion: "${process.env.GATSBY_AXEPTIO_COOKIES_VERSION}",
            googleConsentMode: {
              default: [
                {
                  analytics_storage: 'denied',
                  ad_storage: 'denied',
                  ad_user_data: 'denied',
                  ad_personalization: 'denied',
                  wait_for_update: 500,
                },
                {
                  region: ['US'],
                  analytics_storage: 'granted',
                  ad_storage: 'granted',
                  ad_user_data: 'granted',
                  ad_personalization: 'granted',
                  wait_for_update: 500,
                }
              ]
            }
          };
          (function(d, s) {
            var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
            e.async = true; e.src = "//static.axept.io/sdk.js";
            t.parentNode.insertBefore(e, t);
          })(document, "script");
        `}
      </Script>

      <main>
        <Navigation
          navigationButtons={
            getHeaderItems(header, partnershipLink)['navigationButtons']
          }
          items={getHeaderItems(header, partnershipLink)['items']}
          isBlog={isBlog}
          homePath={process.env.GATSBY_HOME_PATH}
          headerBackgroundColor={headerBackgroundColor}
          translations={translations}
        />
        {children}
        <Suspense fallback={<></>}>
          <Footer
            columns={footerColumnsProps ?? footerColumns[brand]}
            copyrights={footerCopyrights[brand]}
            cta={defaultFooterCta[brand]}
            preFooter={preFooter}
            socials={footerSocials[brand]}
            translations={translations}
            path="/"
            platform={getBrand()}
            footerBackground={footerBackground}
          />
        </Suspense>
      </main>
    </>
  )
}

export default LayoutWihoutHead
