import {
  BigTitle,
  Box,
  Header,
  CheckList,
  InputButton,
  Space,
  Video,
  VideoImage,
  SmallTitle,
  UserInterface,
  AdaptiveImage,
  SliderLogo,
} from '../'
import React, { useState } from 'react'

import styled from 'styled-components'
import { HeaderStyled, HeaderContent } from '../Headers/Header'
import { KillerArgument } from './Header'

/**
 * Style
 */

const HeaderStyle = styled(props => <Header {...props} />)`
  min-height: 560px;
  height: 560px;
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    height: unset;
    min-height: unset;
  }
  background-color: transparent !important;
  margin-bottom: 0px !important;

  .image img {
    vertical-align: bottom !important;
  }

  .videoImageWrapper {
    img {
      border-radius: 58% 45% 21% 15% / 73% 55% 50% 32%;
    }
  }

  @media screen and (min-width: ${UserInterface.breakpoints
      .extraSmallMobile}) and (max-width: ${UserInterface.breakpoints
      .smallMobile}) {
    .videoImageWrapper {
      height: 334px;
    }
  }
  @media screen and (min-width: ${UserInterface.breakpoints
      .smallMobile}) and (max-width: ${UserInterface.breakpoints.mobile}) {
    .videoImageWrapper {
      height: 445px;
    }
  }
  @media screen and (min-width: ${UserInterface.breakpoints.smallMobile}) {
    .videoImageButton {
      top: calc(50% - 40px);
    }
  }

  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    .videoImageButton {
      left: calc(50% + 10px);
      top: calc(50% - 10px);
    }

    .image {
      position: absolute;
      top: -253px;
      left: -108px;
    }

    .image img {
      width: 120%;
      height: 110%;
      max-width: unset;
      max-height: unset;
    }
  }
`

const NoticeBanner = styled.div`
  position: relative;
  z-index: 2;
  background-color: ${UserInterface.colors.specificBlue};
  padding: 22px 10px;
  background-color: white;
`

const BoxHeader = styled(props => <Box {...props} />)`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    margin-bottom: 38px;
  }

  @media screen and (max-width: ${UserInterface.breakpoints.smallMobile}) {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
`
const HeaderHomeContainer = styled.div`
  background: linear-gradient(
    to bottom,
    ${UserInterface.colors.clearBlue},
    ${UserInterface.colors.white} 11.765rem
  );
  ${HeaderStyled} {
    overflow: hidden;

    margin-bottom: unset;
  }

  ${HeaderContent} {
    min-height: 560px;
    height: 560px;

    @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
      height: unset;
      min-height: unset;
    }
  }
`

const CheckListStyled = styled(props => <CheckList {...props} />)`
  height: 7rem;

  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    display: inline-block;
  }

  li {
    margin: 14px;
    margin-left: 0px !important;
  }
`

const VideoStyled = styled(props => <Video {...props} />)`
  height: 0;
  width: 0;
`

const SliderContainer = styled.div`
  padding: 22px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  height: 137px;

  @media screen and (max-width: ${UserInterface.breakpoints.smallMobile}) {
    flex-direction: column;

    h2 {
      padding-bottom: 1rem;
    }
`

const BigTitleStyled = styled(props => <BigTitle {...props} />)`
  font-size: ${({ isSpringly }) => (isSpringly ? '2.8rem' : '3rem')} !important;
  line-height: ${({ isSpringly }) =>
    isSpringly ? '3.6rem' : '4.2rem'} !important;

  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    font-size: 2.4rem !important;
    line-height: 3.2rem !important;
  }
`

const VideoImageContainer = styled(props => <Box {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    height: 334px;
  }
  @media screen and (min-width: ${UserInterface.breakpoints
      .smallMobile}) and (max-width: ${UserInterface.breakpoints.mobile}) {
    height: 445px;
  }
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    display: block;
  }
`

/**
 * Component
 */
const HeaderHome = ({
  image,
  title = '',
  buttonHref,
  hubspotId,
  killerArgument = null,
  videoId,
  translations,
  noticeText,
  checkListContent,
  imageUrlForNotice,
}) => {
  const [wistiaPopoverVisible, setWistiaPopoverVisible] = useState(false)
  /**
   * Handle click events on VideoButtonWrapper component
   */
  const handleVideoButtonWrapperClick = () => {
    // necessary to force rerendering of the <Video> component, Wistia "popoverhide" event being unreliable in the context of React

    const existingScript = document.getElementById('wistia')

    if (!existingScript) {
      const wistiaScript = document.createElement('script')
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js'
      wistiaScript.id = 'wistia'
      document.body.appendChild(wistiaScript)
    }

    setWistiaPopoverVisible(false)
    setTimeout(() => {
      setWistiaPopoverVisible(true)
    }, 100)
  }
  return (
    <HeaderHomeContainer>
      <HeaderStyle translations={translations}>
        <BoxHeader mt={['-1.6rem', '-4.375rem']} align="center" width={0.5}>
          <Space size="big" />
          <Space className="hidden-mobile" size="big" />
          <BigTitleStyled
            color="blue"
            align="left"
            tag="h1"
            isSpringly={process.env.GATSBY_CONTEXT_BRAND === 'SPRINGLY'}
          >
            {title}
          </BigTitleStyled>
          <Space size="small" />
          <CheckListStyled content={checkListContent} />
          <Space size="small" />
          <InputButton
            translations={translations}
            href={buttonHref}
            hubspotId={process.env.GATSBY_HUBSPOT_ID ?? hubspotId}
            className="shadow"
          />
          {killerArgument && (
            <KillerArgument color="middleGrey">{killerArgument}</KillerArgument>
          )}
        </BoxHeader>
        <VideoImageContainer width={0.5}>
          <VideoImage
            image={image}
            onClick={handleVideoButtonWrapperClick}
            videoId={videoId}
            visibleByDefault
          />
        </VideoImageContainer>
      </HeaderStyle>
      <NoticeBanner>
        <SmallTitle font="poppins" align="center" color="middleGrey">
          {noticeText.text}
        </SmallTitle>
      </NoticeBanner>
      <SliderContainer>
        <Box>
          <SliderLogo
            changeInMobile={false}
            slidesToShow={4}
            withArrows={false}
            autoplay
            withDots={false}
          >
            {imageUrlForNotice.map((imageUrl, index) => (
              <AdaptiveImage
                key={index}
                src={imageUrl}
                alt="img"
                maxDpi={1}
                visibleByDefault
              />
            ))}
          </SliderLogo>
        </Box>
      </SliderContainer>
      {videoId && wistiaPopoverVisible && (
        <VideoStyled
          scriptLoadedFromClick
          headerHomeVideo
          videoId={videoId}
          wistiaParameters={[
            'popover=true',
            'popoverShowOnLoad=true',
            'popoverAnimateThumbnail=true',
          ]}
        />
      )}
    </HeaderHomeContainer>
  )
}

export default HeaderHome
