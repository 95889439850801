import React, { FC, useEffect } from 'react'
import styled from 'styled-components'

import { ICardMulti } from '../type'
import { UserInterface, Text } from '../../..'

/**
 * Style
 */

const CardMultiContainer = styled(props => <div {...props} />)`
  display: flex;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 7px 20px 0 rgba(49, 107, 242, 0.3);
  height: 100%;

  @media (max-width: 750px) {
    flex-direction: column;
  }
`

const ImageStyled = styled.img`
  max-width: 280px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  height: 240px;

  @media (max-width: ${UserInterface.breakpoints.smallMobile}) {
    max-width: 240px;
  }

  @media (min-width: 750px) {
    position: absolute;
    width: 35%;
    height: 100%;
    border-radius: 10px 0 0 10px;
    max-width: 425px;
  }

  @media (max-width: 750px) {
    max-width: 100%;
    max-height: 490px;
  }
`

const DescriptionContainer = styled(props => <div {...props} />)`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 750px) {
    padding: 1rem;
    position: relative;
    top: 0;
    bottom: 0;
    width: 63%;
    left: 37%;

    .text {
      font-size: 0.9rem !important;

      @media (min-width: ${UserInterface.breakpoints.smallMobile}) {
        min-height: 1rem !important;
      }
    }

    @media (min-width: ${UserInterface.breakpoints.smallMobile}) {
      min-height: 375px;
    }
  }
`

const CardMultiTitle = styled(props => <div {...props} />)`
  font-size: 1.5rem;
  line-height: 3rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
  color: ${UserInterface.colors.extraDarkGrey};
  font-family: Poppins, sans-serif;
  @media (min-width: ${UserInterface.breakpoints.smallMobile}) {
    font-size: 1.7rem;
  }
`

const TextContent = styled(props => <div {...props} />)`
  .text {
    font-weight: 500 !important;
  }
`

/**
 * Card Multi Component
 */
const CardMulti: FC<ICardMulti> = ({ children, title, image, alt }) => {
  useEffect(() => {
    const preloadImage = new Image()
    preloadImage.src = `${process.env.GATSBY_CDN_HOST}/${image}-2x.webp`
  }, [image])

  return (
    <>
      <CardMultiContainer>
        <ImageStyled
          loading="lazy"
          src={`${process.env.GATSBY_CDN_HOST}/${image}-2x.webp`}
          alt={alt}
        />
        <DescriptionContainer>
          <Text className="">
            <CardMultiTitle>{title}</CardMultiTitle>
          </Text>
          <TextContent>{children}</TextContent>
        </DescriptionContainer>
      </CardMultiContainer>
    </>
  )
}

export default CardMulti
