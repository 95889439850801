import React, { FC, Children, useRef, forwardRef } from 'react'
import styled from 'styled-components'

import { ECardsContainerType, ICards } from './type'
import { Dots, UserInterface, Text } from '../..'
import classNames from 'classnames'
import { useCards } from '../../../hooks'

/**
 * Style
 */
const CardsWrapper = styled(props => <div {...props} />)`
  width: 100%;
  margin: 0 10vw !important;
  padding: 0 !important;

  @media (min-width: 750px) {
    margin: 2rem auto !important;

    &.largeCards {
      max-width: 80%;
    }

    &.cards {
      max-width: 100%;
    }
  }
`

const CardsButtons = styled(props => <div {...props} />)`
  display: flex;
  margin-bottom: 1rem;

  @media (max-width: 750px) {
    display: none;
  }
`

const CardButton = styled(props => <div {...props} />)`
  cursor: pointer;
  flex: 1;
  padding: 1rem 0.5rem;
  background-color: ${UserInterface.colors['white']};
  border-radius: 10px;
  opacity: 0.5;
  text-align: center;
  margin: 0 0.5rem;
  display: block;
  border: 1px solid ${UserInterface.colors['lightGrey']};
  box-shadow: ${({ theme }) =>
    UserInterface.shadows[theme === 'blue' ? 'slider' : 'sliderLight']};

  &.active {
    opacity: 1;
  }

  @media (min-width: ${UserInterface.breakpoints.mobile}) {
    padding: 1.3rem 0;
  }
`

const Card = styled(props => <div {...props} />)`
  opacity: 1;
  transition: opacity 0.5s ease-in-out;

  &.fade-out {
    opacity: 0;
  }

  &.fade-in {
    opacity: 1;
  }
`
// Define the type for the props, including custom props
interface CardContainerProps extends React.HTMLProps<HTMLDivElement> {
  $isCurrentCard?: boolean
}

// Define the base component with forwarded ref and props
const BaseComponent = forwardRef<HTMLDivElement, CardContainerProps>(
  (props, ref) => <div {...props} ref={ref} />
)

// Set a displayName for the component
BaseComponent.displayName = 'CardContainer'

const CardContainer = styled(BaseComponent)`
  position: relative;
  width: 80vw;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  display: block;
  padding: 0 2vw;

  .card {
    height: 100%;
    margin: 0 !important;
  }

  &.fade-out {
    opacity: 0;
  }

  @media (min-width: 750px) {
    display: ${props => (props.$isCurrentCard ? 'block' : 'none')};
    width: 100%;
    padding: 0 1rem;
    margin-left: 0;

    &.cards {
      display: block;
    }
  }
`
const CardsContainer = styled(props => <div {...props} />)`
  transition: margin-left 0.3s ease-in-out;
  margin-left: calc(${props => props.$currentCardId * -80}vw);

  display: flex;
  width: ${props => props.$cardsAmount * 100}vw;

  @media (min-width: 750px) {
    margin-left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;

    &.largeCards {
      display: block;
    }

    &.cards {
      display: flex;
      width: 100%;
      justify-content: center;

      .cards {
        max-width: 370px;
      }
    }
  }
`

/**
 * Cards Multi Component
 */
const CardsMulti: FC<ICards> = ({
  children,
  titles,
  type = ECardsContainerType.LARGE_CARDS,
}) => {
  const childrenArray = Array.from(Array(Children.count(children)).keys())
  const cardsAmount = childrenArray.length

  const {
    displayNextCard,
    displayPreviousCard,
    displayCardFromId,
    isSwiping,
    cardsStatus,
  } = useCards(cardsAmount)

  const swipeRef = useRef(null)
  let startX = 0
  let currentX = 0

  const handleTouchStart = (e: React.TouchEvent) => {
    if (childrenArray.length === 1) return
    startX = e.touches[0].clientX
  }

  const handleTouchMove = (e: React.TouchEvent) => {
    currentX = e.touches[0].clientX
  }

  const handleTouchEnd = () => {
    const difference = currentX - startX
    if (difference > 50) {
      displayPreviousCard()
    } else if (difference < -50) {
      displayNextCard()
    }
  }

  return (
    <CardsWrapper
      className={`${
        type === ECardsContainerType.CARDS ? 'cards' : 'largeCards'
      } `}
    >
      {titles && (
        <CardsButtons>
          {childrenArray.map((title, index) => (
            <CardButton
              key={title}
              onClick={() => displayCardFromId(index)}
              className={classNames({
                active: cardsStatus.currentCardId === index,
              })}
            >
              <Text color="extraDarkGrey" align="center" className="">
                {titles[index]}
              </Text>
            </CardButton>
          ))}
        </CardsButtons>
      )}

      <CardsContainer
        $cardsAmount={cardsAmount}
        $currentCardId={cardsStatus.currentCardId}
        className={`${
          type === ECardsContainerType.CARDS ? 'cards' : 'largeCards'
        } `}
      >
        {React.Children.map(children, (child, index) => {
          const isCurrentCard = cardsStatus.currentCardId === index

          return (
            <CardContainer
              key={index}
              ref={swipeRef}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              className={`${isSwiping ? 'fade-out' : 'fade-in'} ${
                type === ECardsContainerType.CARDS ? 'cards' : 'largeCards'
              } `}
              $isCurrentCard={isCurrentCard}
            >
              {React.cloneElement(child as React.ReactElement, {
                key: index,
                children: React.Children.map(
                  (child as React.ReactElement).props.children,
                  (subChild, subIndex) => <Card key={subIndex}>{subChild}</Card>
                ),
              })}
            </CardContainer>
          )
        })}
      </CardsContainer>
      <Dots
        items={childrenArray}
        displayItemId={displayCardFromId}
        currentItemId={cardsStatus.currentCardId}
      />
    </CardsWrapper>
  )
}

export default CardsMulti
